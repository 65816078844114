import { useState } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { FaBars, FaKeyboard, FaMicrophone } from 'react-icons/fa'; // Importing icons
import "./styles.css";

const MainLayout = ({ children, setTypeChat }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to control sidebar visibility

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className='main-layout'>
      <div className='hamburger' onClick={toggleSidebar}>
        <FaBars className="fixed" size={24} onClick={toggleSidebar} />
      </div>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <Sidebar rootStyles={{
          height: "100vh",
          transition: "transform 0.3s ease", // Smooth transition
          transform: isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)' // Slide effect
        }}>
          <Menu className='mt-12'>
            
            <MenuItem className="-ml-3.5 mr-2 border-t-2" icon={<FaKeyboard />} onClick={() => {
              setTypeChat("text");
              toggleSidebar();
            }}>
              Prompt écrit
            </MenuItem>
            <MenuItem className="-ml-3.5 border-y-2" icon={<FaMicrophone />} onClick={() => {
              setTypeChat("voice");
              toggleSidebar();
            }}>
              Prompt oral
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
      <div className='content'>
        {children}
      </div>
    </div>
  );
}

export default MainLayout;
