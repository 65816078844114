import Markdown from 'markdown-to-jsx';
import "./style.css";

const ChatLeft = ({data}) => {


  return (
    <div className="w-full text-token-text-primary" dir="auto" data-testid="conversation-turn-3" data-scroll-anchor="true">
      <div className="text-base py-[18px] px-3 md:px-4 m-auto md:px-5 lg:px-1 xl:px-5">
        <div className="mx-auto flex flex-1 gap-4 text-base md:gap-5 lg:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
          <div className="flex-shrink-0 flex flex-col relative items-end">
            <div>
              <div className="pt-0">
                <div className="gizmo-shadow-stroke flex h-8 w-8 items-center justify-center overflow-hidden rounded-full">
                  <div className="h-full w-full">
                    <div className="gizmo-shadow-stroke overflow-hidden rounded-full">
                      <img
                        src="https://files.oaiusercontent.com/file-s5eGIgUyDUR1b2tJjlSqseUE?se=2124-02-07T17%3A39%3A41Z&amp;sp=r&amp;sv=2021-08-06&amp;sr=b&amp;rscc=max-age%3D1209600%2C%20immutable&amp;rscd=attachment%3B%20filename%3Dinw%25401024.png&amp;sig=OPwGYRL4JHX8jMxju6d/Yp0I%2BlNjfkDd%2BiW9hJ8ABP4%3D"
                        className="h-full w-full bg-token-main-surface-secondary"
                        alt="GPT"
                        width="80"
                        height="80"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="group/conversation-turn relative flex w-full min-w-0 flex-col agent-turn">
            <div className="flex-col gap-1 md:gap-3">
              <div className="flex max-w-full flex-col flex-grow">
                <div
                  data-message-author-role="assistant"
                  data-message-id="a6597c1d-ae9e-4ba9-9e88-b6617241e5ab"
                  dir="auto"
                  className="min-h-[20px] text-message flex w-full flex-col items-end gap-2 whitespace-pre-wrap break-words [.text-message+&]:mt-5 overflow-x-auto"
                >
                  <div className="flex w-full flex-col gap-1 empty:hidden first:pt-[3px]">
                    <div className="markdown prose w-full dark:prose-invert dark">
                      <Markdown  options={{ forceInline: true }}>{data}</Markdown>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pr-2 lg:pr-0"></div>
              <div className="mt-3 w-full empty:hidden">
                <div className="text-center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatLeft;
