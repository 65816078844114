import './App.css';
import ErrorBoundary from './components/ErrorBoundary';
import InWealthNofification from './components/InWealthNotification';
import RoutAuthProvider from './routes/Routes';

function App() {
  return (
    <div className="core">
      <ErrorBoundary>
        <RoutAuthProvider />
      </ErrorBoundary>
      <InWealthNofification />
    </div>
  );
}

export default App;
