import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, toast } from 'react-toastify';
import { makeid } from "../utils/func";

export const toastEmmit = (type, message, callback=false, id=false, typeUpdate='success') => {

  let toastId;

  switch (type) {
    case "info":
      toastId = toast.info(message);
      break;
    case "success":
      toastId = toast.success(message);
      break;
    case "warning":
      toastId = toast.warning(message);
      break;
    case "error":
      toastId = toast.error(message);
      break;
    case "promise":
      toastId = toast.promise(callback, message);
      break;
    case "loading":
      toastId = toast.loading(message);
      break;
    case "update":
      const newId = makeid(6);

      toast.update(id, {
        render: message,
        type: typeUpdate,
        autoClose: 5000,
        closeOnClick: true,
        toastId: newId,
        isLoading: false
      });

      break;
    default:
      toastId = toast(message)
      break;
  }
  
  return toastId;
}

const InWealthNofification = (props) => {
  return (
    <ToastContainer
          position="bottom-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
  )
}

export default InWealthNofification;