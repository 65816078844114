import { RouterProvider, createBrowserRouter, useSearchParams } from "react-router-dom";
import ErrorPage from "../pages/ErrorPage";
import InWealthChat from "../components/chat/InWealthChat";
import { ADMIN_ROLE, InWealthRoute, SECURITY_ROLE, USER_ROLE } from "../components/auth/auth";
import MainLayout from "../layout/MainLayout";
import { useState } from "react";

const RoutAuthProvider = (props) => {
  const [typeChat, setTypeChat] = useState("text");

  const routes = createBrowserRouter([
    {
      name: "Home",
      path: '/',
      errorElement: <ErrorPage />,
      element: <MainLayout setTypeChat={setTypeChat}><InWealthChatWrapper typeChat={typeChat} /></MainLayout>,
      // element: <InWealthRoute  roles={[ SECURITY_ROLE, USER_ROLE, ADMIN_ROLE ] } ><InWealthChat /></InWealthRoute>,
    },
  ]);

  return (
    <>
      <RouterProvider router={routes} />
    </>
  );
};

const InWealthChatWrapper = ({ typeChat }) => {
  const [searchParams] = useSearchParams();
  const chatId = searchParams.get("userID"); // Récupère le paramètre `id` de l'URL

  return <InWealthChat typeChat={typeChat} chatId={chatId} />;
};

export default RoutAuthProvider;
