import React, { useEffect } from 'react';


const CalendlyWidget = () => {
  useEffect(() => {
    // Charger le script Calendly
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    // Initialiser le widget Calendly lorsque le script est chargé
    script.onload = () => {
      window.Calendly.initInlineWidget({
        url: 'https://calendly.com/inwealth/30min',
        parentElement: document.getElementById('calendly-embed')
      });
    };

    // Nettoyer le script lorsque le composant est démonté
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="calendly-embed" style={{ minWidth: '320px', height: '700px' }}></div>
  );
};

export default CalendlyWidget;
