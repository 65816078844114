import { useRef, useState, useEffect } from "react";

import { IoIosCloseCircle } from "react-icons/io";
import MessageInput from "./Message";
import ChatContent from "./ChatContent";
import Specter from "./Specter";
import CalendlyWidget from "./CalendlyWidget";

const InWealthChat = (props) => {
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const isRecording = useRef(false);
  const [all_messages, set_all_message] = useState([]);
  const [fastMessage, setFastMessage] = useState("");
  const [showCalendly, setShowCalendly] = useState(false);
  const [countries, setCountries] = useState([]);
  const [all_message_audio, set_all_message_audio] = useState([]);
  const [residence, setResidence] = useState(null)
  const [showFast, setShowFast] = useState(false)



  const closeCalendly = () => {
    setShowCalendly(false);
  };

  return (
    <>
      {showCalendly ? (
        <>
          <IoIosCloseCircle
            onClick={closeCalendly}
            className="fixed iconeReset2 h-7 w-7"
          />
          <CalendlyWidget />
        </>
      ) : (
        <>
          <div>
            {audioPlaying && audioUrl && <Specter url={audioUrl} all_message_audio={all_message_audio} set_all_message_audio={set_all_message_audio} />}
          </div>
          <div className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden">
            <main className="relative h-full w-full flex-1 overflow-auto transition-width">
              <div className="flex h-full flex-col focus-visible:outline-0 p-0 ">
                
  
                  <ChatContent
                    audioChunks={audioChunks}
                    all_messages={all_messages}
                    set_all_message={set_all_message}
                    fastMessage={fastMessage}
                    setFastMessage={setFastMessage}
                    residence={residence}
                    showFast={showFast}
                  />

                <MessageInput
                  ChatId={props.chatId}
                  setShowCalendly={setShowCalendly}
                  chatType={props.typeChat}
                  isRecording={isRecording}
                  setAudioUrl={setAudioUrl}
                  setAudioPlaying={setAudioPlaying}
                  audioChunks={audioChunks}
                  setAudioChunks={setAudioChunks}
                  all_messages={all_messages}
                  set_all_message={set_all_message}
                  fastMessage={fastMessage}
                  setFastMessage={setFastMessage}
                  all_message_audio={all_message_audio}
                  set_all_message_audio={set_all_message_audio}
                  residence ={residence}
                  setResidence={setResidence}
                  setShowFast={setShowFast}
                />
              </div>
            </main>
          </div>
        </>
      )}
    </>
  );
};

export default InWealthChat;
