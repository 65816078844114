import React, { useState, useEffect, useRef } from "react";
import OpusRecording from "./OpusRecording";
import { createClient } from "@supabase/supabase-js";
import { FaStop } from "react-icons/fa";
import { useSet } from "react-use";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY; // Assurez-vous d'utiliser le préfixe REACT_APP_ pour accéder à une variable d'environnement
const supabase = createClient(supabaseUrl, supabaseKey);
const MessageInput = ({
  audioChunks,
  setAudioChunks,
  setAudioPlaying,
  setAudioUrl,
  isRecording,
  chatType,
  all_messages,
  set_all_message,
  fastMessage,
  setFastMessage,
  setShowCalendly,
  ChatId,
  residence,
  setResidence,
  setShowFast
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const textareaRef = useRef(null);
  const ws = useRef(null); // WebSocket reference for text chat
  const lastMessageFromUser = useRef(null);
  const [id, setId] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const stopGeneration = useRef(false);

  const [isOpen, setIsOpen] = useState(false);
  const [emaile, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const [isLoadingSpin, setIsLoadingSpin] = useState(false);

  const [user, setUser] = useState(null);
  const [creneauIsAvailable, setCreneauIsAvailable] = useState(false);
  const [emailUserFromLLM, setEmailUserFromLLM] = useState("");
  const [hide, setHide] = useState(false);
  const email = useRef("");
  const name = useRef("");
  const [emailChangedOnAudio, setmailChangedOnAudio] = useState(false)

  const sendTimestampRef = useRef(null);
  const [disabledTextArea, setDisabledTextArea] = useState(true)

  function stringToHex(str) {
    let hexString = "";
    for (let i = 0; i < str.length; i++) {
      hexString += str.charCodeAt(i).toString(16); // Convertit chaque caractère en code hexadécimal
    }
    return hexString;
  }

  const handleSubmitOui = (event) =>{
    if (event) {
        event.preventDefault();
      }
     
    
    if (
        chatType === "text" &&
        ws.current &&
        ws.current.readyState === WebSocket.OPEN
      ) {
        
        let message = "Oui, je veux bien."
       
        const messageData = {
          user_id: id,
          prompt: message,
        };
        ws.current.send(JSON.stringify(messageData));
        setMessage(""); // Clear the input field after sending
        set_all_message((prevMessages) => [...prevMessages, "Oui‎     ‎"]);
        setHide(false)
      }
  }

  const handleSubmitNon = (event) =>{
    if (event) {
        event.preventDefault();
      }
      console.log("NOn")
    if (
        chatType === "text" &&
        ws.current &&
        ws.current.readyState === WebSocket.OPEN
      ) {
        let message ="Non, ça ira."
       
        const messageData = {
          user_id: id,
          prompt: message,
        };
        ws.current.send(JSON.stringify(messageData));
        setMessage(""); // Clear the input field after sending
        set_all_message((prevMessages) => [...prevMessages, "Non‎     ‎"]);
        setHide(false)
      }
    
  }
  const getResidence = (indice) =>{
    const residents = {
      "fr": "Vous êtes résident fiscal Français",
      "ch": "Vous êtes résident fiscal Suisse",
      "mc": "Vous êtes résident fiscal Monégasque",
      "be": "Vous êtes résident fiscal Belge",
      "uk": "Vous êtes résident fiscal Britannique",
      "it": "Vous êtes résident fiscal Italien",
      "es": "Vous êtes résident fiscal Espagnol",
      "lu": "Vous êtes résident fiscal Luxembourgeois"
    };
    
    
    
    if (residents.hasOwnProperty(indice)) {
      console.log(residents[indice]);
      return residents[indice]
    } else {
      console.log("Indice non trouvé dans le JSON.");
      return ""
    }
  }

  const fetchUser = async () => {
    const { data, error } = await supabase
      .from("profiles")
      .select()
      .eq("id", ChatId);
    if (error) {
      console.error("Erreur lors de la récupération des données : ", error);
    } else {
      setResidence(getResidence(data[0].residence))
      email.current = data[0].email;
      name.current = (data[0].full_name != null || data[0].full_name != "")? data[0].username+" "+data[0].full_name : data[0].username;
      console.log("name.currrent",name.current)
      setEmailUserFromLLM(data[0].email);
      setUser({
        name: (data[0].full_name != null || data[0].full_name != "")? data[0].username+" "+data[0].full_name : data[0].username,
        email: data[0].email,
      })
      // email.current="andry.diris@gmail.com"
      console.log(
        (data[0].full_name != null || data[0].full_name != "")? data[0].username+" "+data[0].full_name : data[0].username
      );
      console.log(
        data[0].full_name != "" ? data[0].full_name : data[0].username
      );
      return {
        name: (data[0].full_name != null || data[0].full_name != "")? data[0].username+" "+data[0].full_name : data[0].username,
        email: data[0].email,
        //"email" : "andry.diris@gmail.com"
      };
    }
  };

  const toggleModalx = () => {
    setIsOpen(false);
    setEmailError(""); // Réinitialise le message d'erreur à chaque ouverture du modal
  };

  const toggleModal = () => {
    if (isOpen == false) {
      setIsOpen(!isOpen);
    }
    if (emailError.length != 0 && isOpen) {
      setIsOpen(!isOpen);
      setEmail(""); // Réinitialise l'email à chaque ouverture du modal
      setEmailError(""); // Réinitialise le message d'erreur à chaque ouverture du modal
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value);
    setUser(prevState => ({
      ...prevState,      // Copie tous les autres attributs
      email: e.target.value      // Met à jour uniquement l'attribut 'email'
    }));
  };

  const handleSubmitEmail = () => {
    console.log("Email saisi :", emaile);

    toggleModal(); // Ferme le modal après soumission
    console.log("isOpen", isOpen);
    setIsOpen(false);
    let valid = validateEmail(emaile);
    email.current = emaile;
    console.log(valid);
    if (valid) {
      setEmailUserFromLLM(emaile);
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        console.log({ name: name.current, email: emaile });
        ws.current.send(JSON.stringify({ name: name.current, email: emaile }));
      }
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Veuillez entrer un email valide.");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };
  // Récupération des données depuis Supabase

  function addClickListenerIfElementExists() {
    const element = document.getElementById("btnCalendly");
    if (element) {
      element.addEventListener("click", function () {
        setIsLoadingSpin(true);
        //alert("cliqué")
        element.disabled = true;
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          let usera = fetchUser();

          if (usera) {
            usera
              .then((data) => {
                if (data.email != null) {
                  console.log("data", data);
                  setEmailUserFromLLM(data.email);
                  ws.current.send(JSON.stringify(data));
                } else {
                  toggleModal();
                }
              })
              .finally(() => {
                console.log("setEmailUserFromLLM", emailUserFromLLM);
              });
          }
        }
      });
    }
  }

  // Création d'un MutationObserver pour surveiller les changements dans le DOM
  const observer = new MutationObserver((mutationsList, observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === "childList") {
        // Vérifier si l'élément avec l'ID est maintenant présent dans le DOM
        if (document.getElementById("btnCalendly")) {
          // Une fois l'élément trouvé, on ajoute l'event listener
          addClickListenerIfElementExists();
          // On arrête l'observation une fois l'élément trouvé
          observer.disconnect();
          break;
        }
      }
    }
  });

  //Check_data
  // Fonction qui vérifie qu'il y a des créneaux disponibles dans la réponse
  const checkCreneauDisponible = (llm_pred) => {
    const mot_cle = "Voici quelques propositions de date";

    const llm_response_split = llm_pred.split(",");

    const createButton = (word, onClick, disabled) =>
      React.createElement(
        "button",
        {
          className: disabled
            ? "creneau-btn-deactive opacity-50 cursor-not-allowed"
            : "creneau-btn bg-black text-white rounded-lg px-1 py-1 border border-black hover:bg-white hover:text-black transition duration-300 my-1",
          key: word,
          onClick: () => {
            setButtonsDisabled(true);
            onClick(convertFrenchDateToEnglishFormat(word));
          },
          disabled: disabled, // Désactive le bouton
        },
        word
      );

    const convertDateToFrenchFormat = (dateString) => {
      console.log("convertion ok");
      const monthsEn = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const monthsFr = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];

      const [day, month, year, atTime, ...timeArray] =
        dateString.split(/[\s,]+/);
      console.log("month", month);
      const time = timeArray.join(" ");
      const monthIndex = monthsEn.indexOf(month);
      console.log("monthIndex", monthIndex);

      if (monthIndex !== -1) {
        const monthFr = monthsFr[monthIndex];
        console.log(`${day} ${monthFr} ${year} à ${time}`);
        return `${day} ${monthFr} ${year} à ${time}`;
      }
      console.log("dateString", dateString);
      // Retourne la date d'origine si le mois n'est pas trouvé
      return dateString;
    };

    const convertFrenchDateToEnglishFormat = (dateString) => {
      const monthsEn = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const monthsFr = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];

      const [day, month, year, atTime, ...timeArray] =
        dateString.split(/[\s,]+/);
      var times = timeArray.join(" ");
      var time_temp = times.split("h");
      const time = time_temp.join(":");
      const monthIndex = monthsFr.indexOf(month);

      if (monthIndex !== -1) {
        const monthEn = monthsEn[monthIndex];
        return `${day} ${monthEn} ${year} at ${time}`;
      }

      // Retourne la date d'origine si le mois n'est pas trouvé
      return dateString;
    };

    const extractWordsStartingWith = (input, separator) => {
      const words = input.split(separator);
      words.shift(); // effacer le premier élément

      const convertedDates = words.filter((word) => word.trim() !== ""); // Vérifie que le mot n'est pas vide après avoir été trimé
      console.log("convertedDates", convertedDates);
      const convertedDatesFiltred = convertedDates.map((dateString) =>
        convertDateToFrenchFormat(dateString)
      );
      console.log("convertedDatesFiltred", convertedDatesFiltred);
      return convertedDatesFiltred;
    };

    const processInput = (input, keyword, onButtonClick) => {
      if (input.includes(keyword)) {
        const extracted_datetime = extractWordsStartingWith(input, "$");
        console.log("extracted_datetime", extracted_datetime);
        const extracted_email = extractWordsStartingWith(
          llm_response_split[1],
          "_$"
        );

        if (emailUserFromLLM === "") {
          setEmailUserFromLLM(extracted_email[0]);
        }

        if (extracted_datetime.length > 0) {
          return React.createElement(
            "div",
            { className: "creneau-btn-container" },
            React.createElement(
              "p",
              { style: { marginBottom: "10px" } },
              `${keyword} : `
            ),
            React.createElement(
              "div",
              { style: { display: "flex", flex: 1, flexDirection: "column" } },
              extracted_datetime.map((word) =>
                createButton(word, onButtonClick, buttonsDisabled)
              )
            )
          );
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    const buttonClickHandler = (word) => {
      setIsLoadingSpin(true);
      const buttons = document.getElementsByClassName("creneau-btn");

      // Parcourir tous les éléments et les désactiver
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].disabled = true; // Désactiver le bouton
        buttons[i].classList.add("disabled"); // Ajouter une classe CSS pour un style visuel
      }

      if (creneauIsAvailable) {
      } else {
        //setRequestLoaderState(true);
        let final_text = `Puis-je prendre rendez-vous pour le ${word}. Avec ${email.current}`;
        console.log(final_text);
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(
            JSON.stringify({
              date: final_text,
              full_name: name.current,
              mail: email.current,
            })
          );
          setCreneauIsAvailable(true);
        }
      }
    };

    // Appel des fonctions
    const result = processInput(
      llm_response_split[0],
      mot_cle,
      buttonClickHandler
    );

    return !result ? llm_pred : result;
  };

  function generateId() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const idLength = 10;
    let result = "";

    for (let i = 0; i < idLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    return result;
  }

  useEffect(() => {
    setAudioChunks([]);
    setAudioPlaying(false);
    setAudioUrl(null);
    isRecording.current = false;
    set_all_message([]);
    setFastMessage("")
    setShowFast(false)
    if (chatType === "text") {
      
      if(!ws.current){
        ws.current = new WebSocket(
          process.env.REACT_APP_WEBSOCKET_URL + "/ws_predict_text"
        );
      }
      

      ws.current.onopen = () => {
        console.log("WebSocket connected");
        setId(generateId());
        setShowFast(true)
      };

      ws.current.onclose = () => {
        console.log("WebSocket disconnected");
        setShowFast(false)
      };

      ws.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      ws.current.onmessage = (event) => {
        setIsLoadingSpin(false);
        console.log(event.data);
        //console.log(stringToHex(event.data));
        const receiveTimestamp = Date.now();
        if (sendTimestampRef.current) {
          const processingTime = receiveTimestamp - sendTimestampRef.current;
          console.log("Temps de traitement:", processingTime, "ms");
          sendTimestampRef.current=Date.now()
        }

        if (!stopGeneration.current) {
          setIsGenerating(true);
          let m = checkCreneauDisponible(event.data);
          if (m != event.data) setIsGenerating(false);

          if (typeof m === "string" && m.includes("[calendly]")) {
            m = m.replace(
              "[calendly]",
              `<button  id="btnCalendly" className="mt-2 bg-black text-white rounded-lg px-2 py-1 border border-black hover:bg-white hover:text-black transition duration-300">Prendre un RDV</button>`
            );
            // Configurer le MutationObserver pour observer l'ajout de nouveaux éléments au body
            observer.observe(document.body, {
              childList: true,
              subtree: true, // Inclure les sous-arbres pour capter les éléments ajoutés en profondeur
            });
          }
          if (typeof m === "string" && m.includes("[PPTX]")) {
            m = m.replace(
              "[PPTX]",
              `
                         <div className="mt-3 mb-2 bg-slate-100 rounded-md pl-2 py-2 flex flex-row">
                            <div> <svg height="30px" width="30px" version="1.1" id="Capa_1" 
	 viewBox="0 0 56 56" xmlSpace="preserve">
<g>
	<path style="fill:#E9E9E0;" d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
		c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"/>
	<polygon style="fill:#D9D7CA;" points="37.5,0.151 37.5,12 49.349,12 	"/>
	<path style="fill:#CC4B4C;" d="M19.514,33.324L19.514,33.324c-0.348,0-0.682-0.113-0.967-0.326
		c-1.041-0.781-1.181-1.65-1.115-2.242c0.182-1.628,2.195-3.332,5.985-5.068c1.504-3.296,2.935-7.357,3.788-10.75
		c-0.998-2.172-1.968-4.99-1.261-6.643c0.248-0.579,0.557-1.023,1.134-1.215c0.228-0.076,0.804-0.172,1.016-0.172
		c0.504,0,0.947,0.649,1.261,1.049c0.295,0.376,0.964,1.173-0.373,6.802c1.348,2.784,3.258,5.62,5.088,7.562
		c1.311-0.237,2.439-0.358,3.358-0.358c1.566,0,2.515,0.365,2.902,1.117c0.32,0.622,0.189,1.349-0.39,2.16
		c-0.557,0.779-1.325,1.191-2.22,1.191c-1.216,0-2.632-0.768-4.211-2.285c-2.837,0.593-6.15,1.651-8.828,2.822
		c-0.836,1.774-1.637,3.203-2.383,4.251C21.273,32.654,20.389,33.324,19.514,33.324z M22.176,28.198
		c-2.137,1.201-3.008,2.188-3.071,2.744c-0.01,0.092-0.037,0.334,0.431,0.692C19.685,31.587,20.555,31.19,22.176,28.198z
		 M35.813,23.756c0.815,0.627,1.014,0.944,1.547,0.944c0.234,0,0.901-0.01,1.21-0.441c0.149-0.209,0.207-0.343,0.23-0.415
		c-0.123-0.065-0.286-0.197-1.175-0.197C37.12,23.648,36.485,23.67,35.813,23.756z M28.343,17.174
		c-0.715,2.474-1.659,5.145-2.674,7.564c2.09-0.811,4.362-1.519,6.496-2.02C30.815,21.15,29.466,19.192,28.343,17.174z
		 M27.736,8.712c-0.098,0.033-1.33,1.757,0.096,3.216C28.781,9.813,27.779,8.698,27.736,8.712z"/>
	<path style="fill:#CC4B4C;" d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"/>
	<g>
		<path style="fill:#FFFFFF;" d="M17.385,53h-1.641V42.924h2.898c0.428,0,0.852,0.068,1.271,0.205
			c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991s0.308,0.822,0.308,1.306
			c0,0.511-0.087,0.973-0.26,1.388c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656
			s-0.921,0.232-1.449,0.232h-1.217V53z M17.385,44.168v3.992h1.504c0.2,0,0.398-0.034,0.595-0.103
			c0.196-0.068,0.376-0.18,0.54-0.335c0.164-0.155,0.296-0.371,0.396-0.649c0.1-0.278,0.15-0.622,0.15-1.032
			c0-0.164-0.023-0.354-0.068-0.567c-0.046-0.214-0.139-0.419-0.28-0.615c-0.142-0.196-0.34-0.36-0.595-0.492
			c-0.255-0.132-0.593-0.198-1.012-0.198H17.385z"/>
		<path style="fill:#FFFFFF;" d="M32.219,47.682c0,0.829-0.089,1.538-0.267,2.126s-0.403,1.08-0.677,1.477s-0.581,0.709-0.923,0.937
			s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219C28.222,52.984,28.026,53,27.898,53h-3.814V42.924h3.035
			c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073s0.74,0.955,0.95,1.524C32.114,46.494,32.219,47.08,32.219,47.682z
			 M27.352,51.797c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244
			c-0.101-0.41-0.294-0.781-0.581-1.114s-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629H27.352z"/>
		<path style="fill:#FFFFFF;" d="M36.266,44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H40.9v1.244H36.266z"/>
	</g>
</g>
</svg></div><div className="flex-auto pt-2 w-max text-xs text-center">${name.current == "" || name.current == null ? "Première partie de votre étude...pdf" : `${name.current} - Première partie....pdf`} </div> </div>`
            );
          }
          set_all_message((prevMessages) => {
            if (prevMessages && prevMessages.length > 0) {
              const lastMessage = prevMessages[prevMessages.length - 1];

              let sohait = [
                "Souhaitez-vous que je vous explique le premier enjeu : les réflexions patrimoniales avant le départ?",
                "Souhaitez-vous une explication du premier enjeu : les réflexions patrimoniales avant le départ?",
                "Souhaitez-vous que je vous explique le premier enjeu : les réflexions patrimoniales avant le départ ?",
                "Désirez-vous que je vous présente le premier enjeu : les réflexions patrimoniales avant le départ ?",
                "Voulez-vous que je vous expose le premier enjeu : les réflexions patrimoniales avant le départ ?",
                "Souhaitez-vous une explication du premier enjeu : les réflexions patrimoniales avant le départ ?",
                "Préférez-vous que je vous détaille le premier enjeu : les réflexions patrimoniales avant le départ ?",
                " Souhaitez-vous que je vous explique le premier enjeu : les réflexions patrimoniales avant le départ?",
                " Souhaitez-vous une explication du premier enjeu : les réflexions patrimoniales avant le départ?",
                " Souhaitez-vous que je vous explique le premier enjeu : les réflexions patrimoniales avant le départ ?",
                " Désirez-vous que je vous présente le premier enjeu : les réflexions patrimoniales avant le départ ?",
                " Voulez-vous que je vous expose le premier enjeu : les réflexions patrimoniales avant le départ ?",
                " Souhaitez-vous une explication du premier enjeu : les réflexions patrimoniales avant le départ ?",
                " Préférez-vous que je vous détaille le premier enjeu : les réflexions patrimoniales avant le départ ?",
                "Souhaitez-vous planifier une session privée de 30 minutes et recevoir l’enjeu fiscal de l’exit-tax détaillé? ",
                " Souhaitez-vous que je vous explique le premier enjeu : les réflexions patrimoniales avant le départ?",
                " Souhaitez-vous une explication du premier enjeu : les réflexions patrimoniales avant le départ?",
                " Souhaitez-vous que je vous explique le premier enjeu : les réflexions patrimoniales avant le départ?",
                " Désirez-vous que je vous présente le premier enjeu : les réflexions patrimoniales avant le départ?",
                " Voulez-vous que je vous expose le premier enjeu : les réflexions patrimoniales avant le départ?",
                " Souhaitez-vous une explication du premier enjeu : les réflexions patrimoniales avant le départ?",
                " Préférez-vous que je vous détaille le premier enjeu : les réflexions patrimoniales avant le départ?",
              ];

              try{

                //alert("ato")
                let all_message_split = lastMessage.split("<br>");
                //console.log(all_message_split)
                let last_element =
                  all_message_split[all_message_split.length - 1];
                //console.log(last_element);
              //   sohait.forEach((item) => {
              //     console.log(item);
              //   });

                if (sohait.includes(last_element)) {
                  setHide(true)
                  console.log("Hide the boutton")
                }
              }catch(error){
                console.log(error)
              }

              

              if (
                typeof lastMessage == "string" &&
                lastMessage.endsWith("‎     ‎")
              ) {
                return [...prevMessages, m];
              } else if (lastMessage === lastMessageFromUser.current) {
                return [...prevMessages, m]; // Ajouter event.data comme nouveau message
              } else {
                if (typeof lastMessage == "string") {
                  return [...prevMessages.slice(0, -1), lastMessage + m];
                } else {
                  return [...prevMessages, m];
                }
              }
            } else {
              return [m];
            }
          });
        }

        if (event.data === "‎     ‎") setIsGenerating(false);
      };

      return () => {
        if (ws.current && chatType!="text") {
          ws.current.close();
        }
      };
    }
  }, [chatType]);

  useEffect(() => {
    console.log("fastMessage", fastMessage);
    if(chatType=="text") setMessage(fastMessage);
  }, [fastMessage]);

  useEffect(() => {
    
    if(message===fastMessage){
      handleSubmit(null)
      setDisabledTextArea(false)
    }
    
  }, [message]);

  useEffect(() => {
    if (name.current == "") {
      fetchUser();
    }
  }, [name.current]);

  const handleSubmit = (event) => {
    sendTimestampRef.current = Date.now();
    if (event) {
      event.preventDefault();
    }
    
    lastMessageFromUser.current = message;
    if (
      chatType === "text" &&
      ws.current &&
      ws.current.readyState === WebSocket.OPEN
    ) {
      const messageData = {
        user_id: id,
        prompt: message,
      };
      ws.current.send(JSON.stringify(messageData));
      setMessage(""); // Clear the input field after sending
      set_all_message((prevMessages) => [...prevMessages, message]);
    }

    // You can add any additional logic for voice chat submission if needed
  };

  const handleStopGeneration = () => {
    stopGeneration.current = true;
    setIsGenerating(false);
  };
  useEffect(() => {
    console.log(isGenerating);
  }, [isGenerating]);

  useEffect(() => {
    const sendButton = document.getElementById("send-button");
    if (sendButton) sendButton.disabled = message.trim() === "";
  }, [message]);

  const handleInputChange = (event) => {
    setMessage(event.target.value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    const maxHeight = 40 * 10;
    textarea.style.height = Math.min(textarea.scrollHeight, maxHeight) + "px";

    if (textarea.scrollHeight > maxHeight) {
      textarea.style.overflowY = "scroll";
    } else {
      textarea.style.overflowY = "hidden";
    }
  };

  return (
    <>
      {isLoadingSpin ? (
        <div className="spin">
          <div className="spinner d-flex"></div>
        </div>
      ) : (
        <>
          <div className="fixed bottom-4 left-0 right-0 w-full md:pt-0 dark:border-white/20 md:border-transparent md:dark:border-transparent">
            <div className="text-base px-3 md:px-4 m-auto md:px-5 lg:px-1 xl:px-5">
              <div className="mx-auto flex flex-1 gap-4 text-base md:gap-5 lg:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem] justify-center">
                {chatType === "text" &&  (
                     !hide ? (
                  <form
                    onSubmit={handleSubmit}
                    className="w-full"
                    type="button"
                  >
                    <div className="relative flex h-full max-w-full flex-1 flex-col justify-center">
                      <div className="absolute bottom-full left-0 right-0 z-20"></div>
                      <div className="flex w-full items-center">
                        <div className="flex w-full flex-col gap-1.5 rounded-[26px] p-1.5 transition-colors bg-[#f4f4f4] dark:bg-token-main-surface-secondary">
                          <div className="flex items-center gap-1.5 md:gap-2">
                            <div className="flex min-w-0 flex-1 flex-col pl-2">
                              <textarea
                                id="prompt-textarea"
                                tabIndex="0"
                                data-id="044fcc3c-421a-468c-b784-d795621415b9"
                                dir="auto"
                                rows="1"
                                className="pt-2 m-0 resize-none border-0 bg-transparent px-0 text-token-text-primary focus:ring-0 max-h-[25dvh] max-h-52 focus:outline-none"
                                style={{ height: "40px", overflowY: "hidden" }}
                                value={message}
                                onChange={handleInputChange}
                                ref={textareaRef}
                                disabled={disabledTextArea}
                              ></textarea>
                            </div>
                            {isGenerating ? (
                              <button
                                onClick={handleStopGeneration}
                                className="mb-1 me-1 flex h-8 w-8 items-center justify-center rounded-full bg-black text-white transition-colors hover:opacity-70 focus-visible:outline-none focus-visible:outline-black disabled:bg-[#D7D7D7] disabled:text-[#f4f4f4] disabled:hover:opacity-100 dark:bg-white dark:text-black dark:focus-visible:outline-white disabled:dark:bg-token-text-quaternary dark:disabled:text-token-main-surface-secondary"
                              >
                                <FaStop />
                              </button>
                            ) : (
                              <button
                                id="send-button"
                                disabled={true}
                                data-testid="send-button"
                                className="mb-1 me-1 flex h-8 w-8 items-center justify-center rounded-full bg-black text-white transition-colors hover:opacity-70 focus-visible:outline-none focus-visible:outline-black disabled:bg-[#D7D7D7] disabled:text-[#f4f4f4] disabled:hover:opacity-100 dark:bg-white dark:text-black dark:focus-visible:outline-white disabled:dark:bg-token-text-quaternary dark:disabled:text-token-main-surface-secondary"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="32"
                                  height="32"
                                  fill="none"
                                  viewBox="0 0 32 32"
                                  className="icon-2xl"
                                >
                                  <path
                                    fill="currentColor"
                                    fillRule="evenodd"
                                    d="M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                    
                    <div className="flex justify-center p-4">
                        <form
                    onSubmit={handleSubmitOui}
                    className=""
                    type="button"
                  >
                        <button
                        
                        className="bg-black text-white rounded-lg px-2 py-1 border border-black hover:bg-white hover:text-black transition duration-300 mr-2"
                        >
                        Oui
                        </button>
                        </form> 
                         <form
                    onSubmit={handleSubmitNon}
                    className=""
                    type="button"
                  >
                        <button
                        className="bg-white text-black rounded-lg px-2 py-1 border border-black hover:bg-black hover:text-white transition duration-300"
                        >
                        Non
                        </button>
                         </form> 
                  </div>
                )
            )}
                {chatType === "voice" && (
                  <div className="flex w-full items-center justify-center">
                    {!isRecording.current && (
                      <OpusRecording
                        isRecording={isRecording}
                        setAudioUrl={setAudioUrl}
                        setAudioPlaying={setAudioPlaying}
                        audioChunks={audioChunks}
                        setAudioChunks={setAudioChunks}
                        setIsLoading={setIsLoading}
                        set_all_message={set_all_message}
                        all_messages={all_messages}
                        user={user}
                        setUser={setUser}
                        isLoadingSpin={isLoadingSpin}
                        setIsLoadingSpin={setIsLoadingSpin}
                        setCreneauIsAvailable={setCreneauIsAvailable}
                        creneauIsAvailable={creneauIsAvailable}
                        setIsOpen={setIsOpen}
                        isOpen={isOpen}
                        emailChangedOnAudio={emailChangedOnAudio}
                        setmailChangedOnAudio={setmailChangedOnAudio}
                        fastMessage={fastMessage}
                        setShowFast={setShowFast}
                      />
                    )}
                  </div>
                )}
                {/* <button
                        onClick={toggleModal}
                        className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        type="button"
                    >
                        Toggle modal
                    </button> */}
                {isOpen && (
                  <div className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen bg-black bg-opacity-50">
                    <div className="relative p-4 w-full max-w-2xl max-h-full">
                      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        {/* Modal header */}
                        <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Entrer votre email
                          </h3>
                          <button
                            onClick={toggleModalx}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            <svg
                              className="w-3 h-3"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                              />
                            </svg>
                            <span className="sr-only">Close modal</span>
                          </button>
                        </div>

                        {/* Modal body */}
                        <div className="p-4 space-y-4">
                          <input
                            type="email"
                            value={emaile}
                            onChange={handleEmailChange}
                            className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Votre email"
                            required
                          />
                          {emailError && (
                            <p className="text-sm text-red-500">{emailError}</p>
                          )}
                        </div>

                        {/* Modal footer */}
                        <div className="flex items-center p-4 border-t border-gray-200 rounded-b dark:border-gray-600">
                          <button
                            onClick={handleSubmitEmail}
                            className="bg-black text-white rounded-lg px-2 py-1 border border-black hover:bg-white hover:text-black transition duration-300 mr-2"
                          >
                            OK
                          </button>
                          <button
                            onClick={toggleModal}
                            className="bg-white text-black rounded-lg px-2 py-1 border border-black hover:bg-black hover:text-white transition duration-300"
                          >
                            Annuler
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MessageInput;
