import InWealthLogo from "../../../assets/img/inw_color.png";

const NoChat = ({fastMessage, setFastMessage, residence,showFast}) => {

    const fastMessageFunction = (event) => {
        event.preventDefault();
        setFastMessage("Je souhaite m'installer en Italie.")
    }
    

  return(
    
    <div className="flex h-full flex-col items-center justify-center text-token-text-primary">
      <img src={InWealthLogo} alt='inwealth-logo' style={{ width: '188px', height: 'auto' }}/>
      <div style={{opacity: 1}}>
          <div className="mx-3 mt-12 flex max-w-3xl flex-wrap items-stretch justify-center gap-4">
          <div className="flex flex-col items-center gap-2">
              <div className="text-center text-2xl font-semibold">iNwealth</div>
              <div className="flex items-center gap-1 text-token-text-tertiary">
                  <div className="flex flex-row items-center space-x-1">
                  
                  <div>
                      <div type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:r12i:"
                      data-state="closed">
                      
                      </div>
                  </div>
                  </div>
              </div>
              <div className="max-w-md text-center text-sm font-normal text-token-text-primary">La prévention fiscale patrimoniale entre
                  la France et l'international</div>
                  <div className="mt-4 max-w-md text-center text-base font-normal text-token-text-primary">{residence}</div>
              <div className="mx-3 mt-12 flex max-w-3xl flex-wrap items-stretch justify-center gap-4">{showFast && <button onClick={fastMessageFunction}
                  className="relative flex w-40 flex-col gap-2 rounded-2xl border border-token-border-light px-3 pb-4 pt-3 text-start align-top text-[15px] shadow-xxs transition enabled:hover:bg-token-main-surface-secondary disabled:cursor-not-allowed">
                  <div className="line-clamp-3 max-w-full text-balance text-center text-gray-600 dark:text-gray-500 break-word">Je souhaite
                      m'installer en Italie</div> 
                  </button>}</div>
              </div>
          </div>
      </div>
    </div>
  )
}

export default NoChat;